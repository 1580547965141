// const APPID = 'wx052e71943bd33b14'; //appid
const APPID = "HZc4FIK4NKzjpeTJaiD"; //appid
//
const url = "https://smj2.51js.net.cn/"; //服务器地址
// const baseUrl1 = "https://smj3.51js.net.cn/api/"; //服务器地址
const baseUrl1 = "http://scm.51js.net.cn/api/"; //服务器地址
// const baseUrl1 = "http://stbz.super-gift.com.cn/sdk/examples/"; //服务器地址
const baseUrl = url + "api/"; //api
const uploadUrl = url + "img/up.php"; //上传地址
module.exports = {
  APPID,
  url,
  baseUrl,
  uploadUrl,
  baseUrl1,
};
