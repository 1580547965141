import { APPID, baseUrl, uploadUrl, baseUrl1 } from "../utils/config.js";
import { toastLoading, getSecret } from "../utils/util";
import { getYTDHMS } from "../utils/date_utils";
import qs from "qs";
import axios from "axios";
import store from "@/store/index.js";

// import { Message } from "element-ui";
// axios.defaults.baseURL = "http://47.112.252.163:9999";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data";

// // 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断

    // const token = store.state.token;
    // token && (config.headers.token = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 404请求不存在
        case 404:
          // Message("网络请求不存在");
          break;
        // 其他错误，直接抛出错误提示
        default:
        // Toast({
        //   message: error.response.data.message,
        //   duration: 1500,
        //   forbidClick: true
        // })
      }
      return Promise.reject(error.response);
    }
  }
);

export function http(url, params = {}) {
  url = baseUrl1 + url;
  params.openid = localStorage.getItem("openid") || Date.now();
  // params.openid = localStorage.getItem("openid") || "18219526918";
  // params.appid = APPID;
  // params.appid = localStorage.getItem("appid") || "";
  let lesseeMsg = localStorage.getItem("lesseeMsg");

  if (lesseeMsg) {
    params.tenant_appid = JSON.parse(lesseeMsg).tenant_appid;
  } else {
    params.tenant_appid = "";
  }
  params.secret = getSecret(params.fun, params.openid, APPID);
  params.timestamp = getYTDHMS();
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
