<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { http } from "../request/http";
export default {
  name: "app",
  methods: {
    _isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },

    queryTenant() {
      http("tenant.php", {
        fun: "getTenantInfo",
        host: window.location.host,
        // host: "shop.shangjiu-gift.com.cn",
      }).then((res) => {
        if (res.code == 0) {
          document.title = res.data[0].title;
          localStorage.setItem("lesseeMsg", JSON.stringify(res.data[0]));
        }
      });
    },
  },
  mounted() {
    this._isMobile() ? this.$store.commit("mobile") : this.$store.commit("pc");
    // localStorage.setItem('tokens','123')
    // localStorage.setItem('openid','18219526918')
    setTimeout(() => {
      document.title = JSON.parse(localStorage.getItem("lesseeMsg")).title;
    }, 1000);
    if (localStorage.getItem("lesseeMsg")) {
      this.queryTenant();
    } else {
      setTimeout(() => {
        this.queryTenant();
      }, 500);
    }
  },
};
</script>

<style lang="scss"></style>
