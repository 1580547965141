import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layoutPcMi: false
  },
  mutations: {
    pc (state) {
      state.layoutPcMi = true
    },
    mobile (state) {
      state.layoutPcMi = false
    }
  },
  actions: {
  },
  modules: {
  }
})
