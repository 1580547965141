<template>
  <el-container style="background-color: #f9f9f9">
    <el-header style="background-color: white">
      <el-row style="padding: 20px 0" type="flex" justify="center">
        <el-col :xl="17" :xs="24" :sm="24" :md="24" :lg="20">
          <el-row type="flex" justify="end">
            <div class="top-rigth">
              <div>
                <el-dropdown @command="routerClick">
                  <span>会员中心<i class="el-icon-caret-bottom"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(item, i) of members"
                      :key="i"
                      :command="!token ? item.activeName : item.path"
                      >{{ item.title }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div @click="routerCollection">收藏（0）</div>
              <div @click="routerCart">购物车</div>
              <div>结账</div>
            </div>
          </el-row>
        </el-col>
      </el-row>
      <el-divider style="margin: 0"></el-divider>
    </el-header>
    <el-main>
      <el-row
        style="background-color: white; padding: 20px 0"
        type="flex"
        justify="center"
        align="middle"
      >
        <el-col :xl="17" :xs="24" :sm="24" :md="24" :lg="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="5">
            <el-image>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </el-col>
        </el-col>
      </el-row>
      <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        direction="ltr"
        :before-close="handleClose"
      >
        <span>我来啦!</span>
      </el-drawer>
      <el-row
        type="flex"
        justify="space-between"
        style="
          background-color: white;
          box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.1);
        "
      >
        <el-button
          style="background-color: #ffda00"
          type="primary"
          icon="el-icon-search"
        ></el-button>
        <el-button
          @click="drawer = true"
          type="primary"
          style="margin-left: 16px"
        >
          点我打开
        </el-button>
        <!--        <el-col :span="17">-->
        <!--          <el-menu-->
        <!--            :default-active="activeMenuIndex"-->
        <!--            class="el-menu-demo"-->
        <!--            mode="horizontal"-->
        <!--            background-color="white"-->
        <!--            text-color="black"-->
        <!--            active-text-color="black"-->
        <!--            @select="handleSelect">-->
        <!--            <el-submenu index="1">-->
        <!--              <template slot="title">我的工作台</template>-->
        <!--              <el-menu-item index="1-1">选项1</el-menu-item>-->
        <!--              <el-menu-item index="1-2">选项2</el-menu-item>-->
        <!--              <el-menu-item index="1-3">选项3</el-menu-item>-->
        <!--              <el-submenu index="2-4">-->
        <!--                <template slot="title">选项4</template>-->
        <!--                <el-menu-item index="2-4-1">选项1</el-menu-item>-->
        <!--                <el-menu-item index="2-4-2">选项2</el-menu-item>-->
        <!--                <el-menu-item index="2-4-3">选项3</el-menu-item>-->
        <!--              </el-submenu>-->
        <!--            </el-submenu>-->
        <!--          </el-menu>-->
        <!--        </el-col>-->
      </el-row>
      <div style="display: flex; justify-content: center">
        <router-view></router-view>
      </div>
    </el-main>
    <el-footer>
      <div class="footer-top-content">
        <div class="flex">
          <div class="item">
            <div class="title">信息咨询</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of infoConsulting"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">客户服务</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of customerService"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">其他</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of other"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">会员中心</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of memberCenter"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom-content">
        <span>点为科技软件研发有限公司</span>
        <span>{{ lesseeMsg.copyright || "" }}</span>
      </div>
    </el-footer>
    <!-- <el-backtop target=".aaaa"></el-backtop> -->
  </el-container>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      drawer: false,
      infoConsulting: [
        {
          title: "关于我们",
          path: "/aboutUs",
        },
        {
          title: "配送信息",
          path: "/distributionInfo",
        },
        {
          title: "隐私策略",
          path: "/privacyPolicy",
        },
        {
          title: "使用条款",
          path: "/termsUse",
        },
      ],
      customerService: [
        {
          title: "联系我们",
          path: "/contactUs",
        },
        {
          title: "退换服务",
          path: "/goodsOrderReturn",
        },
        {
          title: "网站地图",
          path: "/",
        },
      ],
      other: [
        {
          title: "品牌专区",
          path: "/",
        },
        {
          title: "礼品卷",
          path: "/",
        },
        {
          title: "联盟会员",
          path: "/",
        },
        {
          title: "特别优惠",
          path: "/",
        },
      ],
      memberCenter: [
        {
          title: "会员中心",
          path: "/user",
        },
        {
          title: "历史订单",
          path: "/order",
        },
        {
          title: "收藏列表",
          path: "/myCollection",
        },
        {
          title: "订阅咨询",
          path: "/mailSubscribe",
        },
      ],
      members: [
        {
          title: "会员注册",
          activeName: "second",
        },
        {
          title: "会员登录",
          activeName: "first",
        },
      ],
      search: "",
      activeMenuIndex: "",
      token: localStorage.getItem("tokens"),
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.token = localStorage.getItem("tokens");
        console.log(this.token);
        this.routerToken();
      },
      // 深度观察监听
      deep: true,
    },
  },

  methods: {
    isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
    handleSelect(key, keyPath) {
      this.$router.push("/classificationGoods");
    },
    routerCollection() {
      this.$router.push("/myCollection");
    },
    routerCart() {
      this.$router.push("/shoppingCart");
    },
    routerClick(command) {
      console.dir(this.isMobile);
      if (this.isMobile) {
        alert("移动端");
      } else {
        alert("pc端");
      }
      // this.token
      //   ? this.$router.push(`${command}`)
      //   : this.$router.push({
      //     name: 'login',
      //     params: { activeName: command }
      //   })
    },
    routerToken() {
      if (this.token) {
        this.members = [
          {
            title: "会员中心",
            path: "/user",
          },
          {
            title: "我的订单",
            path: "/order",
          },
          {
            title: "我的余额",
            path: "/balance",
          },
          {
            title: "文件下载",
            path: "/fileDownload",
          },
          {
            title: "注销退出",
            path: "/exit",
          },
        ];
      }
    },
  },
  mounted() {
    this.routerToken();
    setTimeout(() => {
      this.lesseeMsg = JSON.parse(localStorage.getItem("lesseeMsg"));
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/index.scss";
.top-rigth {
  font-size: 0.75rem;
  display: flex;
  div {
    cursor: pointer;
    margin: 0 0.625rem;
  }
}
.star-off-class {
  @include circular;
  @include flexCenter;
  cursor: pointer;
}
.footer-top-content {
  background-color: #f1f2f4;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
  .flex {
    width: 70%;
    @include flexCenter($justify: space-around, $align: unset);
    padding: 2.5rem 0;
    .item {
      width: 18%;
      .el-divider {
        margin: 0.625rem 0;
      }
      .title {
        font-size: 1.125rem;
      }
      .text {
        margin-top: 1.25rem;
        i {
          margin-right: 0.625rem;
        }
        span {
          color: #777777;
          font-size: 0.875rem;
          &:hover {
            display: inline-block;
            transform: translateX(1.25rem);
            cursor: pointer;
            transition: transform linear 500ms;
          }
        }
      }
    }
  }
}
.footer-bottom-content {
  width: 100%;
  background-color: #e4e5e7;
  padding: 1.25rem;
  text-align: center;
  box-sizing: border-box;
  color: #777777;
  font-size: 0.875rem;
}
</style>
<style lang="scss">
.el-header {
  padding: 0 !important;
  .el-divider {
    margin: 0;
  }
}
.el-main,
.el-footer {
  padding: 0 !important;
}
body > .el-container {
  margin-bottom: 40px;
}
</style>
