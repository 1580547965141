import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index/index.vue";
import mIndex from "../mobile/index/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    children: [
      {
        path: "/",
        name: "indexContent",
        component: () => import("../views/index/indexContent.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("../views/index/login.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../views/index/register.vue"),
      },

      {
        path: "/detail",
        name: "detail",
        component: () => import("../views/index/goodDetail.vue"),
      },

      {
        path: "/brandlist",
        name: "brandlist",
        component: () => import("../views/index/brandlist.vue"),
      },
      {
        path: "/orderdetail",
        name: "orderdetail",
        component: () => import("../views/index/orderdetail.vue"),
      },
      {
        path: "/search",
        name: "search",
        component: () => import("../views/index/search.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("../views/user/user.vue"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/index/order.vue"),
      },
      {
        path: "/userInfo",
        name: "userInfo",
        component: () => import("../views/user/userInfo.vue"),
      },
      {
        path: "/updatePass",
        name: "updatePass",
        component: () => import("../views/user/updatePass.vue"),
      },
      {
        path: "/addressMana",
        name: "addressMana",
        component: () => import("../views/user/addressMana.vue"),
      },
      {
        path: "/newAddress",
        name: "newAddress",
        component: () => import("../views/user/newAddress.vue"),
      },
      {
        path: "/myCollection",
        name: "myCollection",
        component: () => import("../views/user/myCollection.vue"),
      },
      {
        path: "/fileDownload",
        name: "fileDownload",
        component: () => import("../views/user/fileDownload.vue"),
      },
      {
        path: "/myIntegral",
        name: "myIntegral",
        component: () => import("../views/user/myIntegral.vue"),
      },
      {
        path: "/goodsReturn",
        name: "goodsReturn",
        component: () => import("../views/user/goodsReturn.vue"),
      },
      {
        path: "/balance",
        name: "balance",
        component: () => import("../views/index/balance.vue"),
      },
      {
        path: "/registeredUnion",
        name: "registeredUnion",
        component: () => import("../views/user/registeredUnion.vue"),
      },
      {
        path: "/mailSubscribe",
        name: "mailSubscribe",
        component: () => import("../views/user/mailSubscribe.vue"),
      },
      {
        path: "/shoppingCart",
        name: "shoppingCart",
        component: () => import("../views/index/shoppingCart.vue"),
      },
      {
        path: "/classificationGoods",
        name: "classificationGoods",
        component: () => import("../views/index/classificationGoods.vue"),
      },
      {
        path: "/exit",
        name: "exit",
        component: () => import("../views/user/exit.vue"),
      },
      {
        path: "/goodsOrderReturn",
        name: "goodsOrderReturn",
        component: () => import("../views/goods/goodsOrderReturn.vue"),
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("../views/user/contactUs.vue"),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("../views/info/aboutUs.vue"),
      },
      {
        path: "/present",
        name: "present",
        component: () => import("../views/info/present.vue"),
      },
      {
        path: "/distributionInfo",
        name: "distributionInfo",
        component: () => import("../views/info/distributionInfo.vue"),
      },
      {
        path: "/privacyPolicy",
        name: "privacyPolicy",
        component: () => import("../views/info/privacyPolicy.vue"),
      },
      {
        path: "/termsUse",
        name: "termsUse",
        component: () => import("../views/info/termsUse.vue"),
      },
      {
        path: "/invoicing",
        name: "invoicing",
        component: () => import("../views/index/invoicing.vue"),
      },
      {
        path: "/goodsContrast",
        name: "goodsContrast",
        component: () => import("../views/goods/goodsContrast.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/index/test.vue"),
  },
  {
    path: "/mIndex",
    name: "mIndex",
    component: mIndex,
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  // 兼容chrome
  document.body.scrollTop = 0;
  // 兼容firefox
  document.documentElement.scrollTop = 0;
  // 兼容safari
  window.pageYOffset = 0;
  next();
});

export default router;
