import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vant from "vant";
import "vant/lib/index.css";

// import { get, post } from "../request/http";
import qs from "qs";
Vue.prototype.$qs = qs;
Vue.prototype.$baseUrl = "https://smj2.51js.net.cn";
// Vue.prototype.post = post;
// Vue.prototype.get = get;
Vue.config.productionTip = false;
import VueLazyload from "vue-lazyload";

// import Vconsole from "vconsole";
// let vConsole = new Vconsole();

Vue.use(VueLazyload);
Vue.use(ElementUI);
Vue.use(Vant);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
