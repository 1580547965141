<template>
  <el-container :style="{ 'background-color': layoutPcMi ? '#f9f9f9' : '' }">
    <!-- <el-header style="background-color: white">
      <el-row style="padding: 20px 0" type="flex" justify="center">
        <el-col :xl="17" :xs="24" :sm="24" :md="24" :lg="20">
          <el-row type="flex" justify="end">
            <div class="top-rigth">
              <div>
                <el-dropdown
                  @command="routerClick"
                  :trigger="layoutPcMi ? 'hover' : 'click'"
                >
                  <span v-if="layoutPcMi"
                    >会员中心<i class="el-icon-caret-bottom"></i
                  ></span>
                  <i v-else class="el-icon-user"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(item, i) of members"
                      :key="i"
                      :command="!token ? item.activeName : item.path"
                      >{{ item.title }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div @click="routerCollection">
                <span v-if="layoutPcMi">收藏（{{ collectNum }}）</span>
                <i v-else class="el-icon-star-off"></i>
              </div>
              <div @click="routerCart">
                <span v-if="layoutPcMi">购物车</span>
                <i v-else class="el-icon-shopping-cart-full"></i>
              </div>
              <div>
                <span v-if="layoutPcMi">结账</span>
                <i v-else class="el-icon-star-off"></i>
              </div>
            </div>
          </el-row>
        </el-col>
      </el-row>
      <el-divider style="margin: 0"></el-divider>
    </el-header> -->
    <div style="background: #333">
      <div v-if="layoutPcMi" class="header">
        <div class="header-left">
          <!-- <div>网站地图</div>
          <div>|</div> -->
        </div>
        <div v-if="false" class="header-right">
          <div>admin</div>
          <div class="el-icon-arrow-down"></div>
          <div>|</div>
        </div>
        <div class="header-right2">
          <div @click="to('/login')">登录</div>
          <div @click="to('/register')">注册</div>
          <div>|</div>
        </div>
      </div>
    </div>
    <el-main>
      <!-- <el-row
        style="background-color: white; padding: 20px 0"
        type="flex"
        justify="center"
        align="middle"
      >
        <el-col :xl="17" :xs="24" :sm="24" :md="24" :lg="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="5">
            <el-image>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </el-col>
          <el-col
            v-if="layoutPcMi"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="{ span: 8, pull: 2 }"
            :xl="{ span: 8, pull: 2 }"
          >
            <el-input placeholder="搜索" v-model="search">
              <el-button
                @click="searchBtn"
                style="background-color: #ffda00"
                slot="append"
                type="primary"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </el-col>
          <el-col
            v-if="layoutPcMi"
            :xs="10"
            :sm="10"
            :md="10"
            :lg="{ span: 5, offset: 2 }"
            :xl="{ span: 5, offset: 2 }"
          >
            <div
              style="
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 20px;
                padding: 10px;
                box-sizing: border-box;
              "
            >
              <i class="el-icon-shopping-cart-full"></i>
              0 件商品 - ￥0.00
            </div>
          </el-col>
          <el-col
            v-if="layoutPcMi"
            :xs="{ span: 6, offset: 8 }"
            :sm="{ span: 6, offset: 8 }"
            :md="{ span: 6, offset: 8 }"
            :lg="{ span: 1, offset: 2 }"
            :xl="{ span: 1, offset: 2 }"
          >
            <div class="star-off-class" @click="routerCollection">
              <i class="el-icon-star-off"></i>
            </div>
          </el-col>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="center"
        style="
          background-color: white;
          box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.1);
        "
      >
        <el-col :xl="17" :xs="24" :sm="24" :md="24" :lg="17">
          <el-menu
            v-if="layoutPcMi"
            :default-active="activeMenuIndex"
            class="el-menu-demo"
            mode="horizontal"
            background-color="white"
            text-color="black"
            active-text-color="black"
            @select="handleSelect"
          >

            <el-submenu index="1">
              <template slot="title">全部礼品</template>
              <el-menu-item
                @click="to('/classificationGoods?id=' + item.id)"
                v-for="(item, index) in classificationList"
                :key="index"
              >
                {{ item.name }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item @click="to('/')">商城首页</el-menu-item>
            <el-menu-item style="display: flex; align-items: center">
              <div class="menu-div"></div>
            </el-menu-item>
            <el-menu-item>秋冬新品</el-menu-item>
            <el-menu-item style="display: flex; align-items: center">
              <div class="menu-div"></div>
            </el-menu-item>
            <el-menu-item>开门红</el-menu-item>
            <el-menu-item style="display: flex; align-items: center">
              <div class="menu-div"></div>
            </el-menu-item>
            <el-menu-item>运动户外</el-menu-item>
            <el-menu-item style="display: flex; align-items: center">
              <div class="menu-div"></div>
            </el-menu-item>
            <el-menu-item>促销赠品</el-menu-item>
            <el-menu-item style="display: flex; align-items: center">
              <div class="menu-div"></div>
            </el-menu-item>
            <el-menu-item>代发专区</el-menu-item>
          </el-menu>
          <el-row v-else type="flex" justify="space-between">
            <el-drawer
              size="80%"
              :with-header="false"
              :visible.sync="drawer"
              direction="ltr"
              :before-close="handleClose"
            >
              <div style="padding: 20px; text-align: center">
                <el-input placeholder="搜索" v-model="search">
                  <el-button
                    style="background-color: #ffda00"
                    slot="append"
                    type="primary"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
                <h2>商品分类</h2>
                <el-tree
                  :data="[{ label: '当前流行', children: [{ label: '上衣' }] }]"
                  accordion
                  :props="defaultProps"
                ></el-tree>
              </div>
            </el-drawer>
            <div>
              <el-button
                @click="drawer = true"
                type="primary"
                icon="el-icon-search"
              ></el-button>
            </div>
            <div>
              <el-button
                @click="drawer = true"
                type="primary"
                icon="el-icon-s-fold"
              ></el-button>
            </div>
          </el-row>
        </el-col>
      </el-row> -->

      <div v-if="layoutPcMi" class="nav">
        <div class="nav-content">
          <!-- <img
            src="http://cdn.weilf.cn/Uploads/site271/202008/logo_c9f6b8.png"
            alt=""
          /> -->
          <img :src="lesseeMsg.logo" alt="" />
          <div class="nav-search">
            <input
              placeholder="产品名称/品牌/编号"
              v-model="searchVal"
              type="text"
              @keyup.enter="seek"
            />
            <div @click="seek" class="search-btn">
              <div class="el-icon-search"></div>
            </div>
            <div
              @click="seek('reset')"
              style="font-size: 13px; margin-left: 10px"
              class="search-btn"
            >
              重置
            </div>
          </div>
        </div>
        <div v-if="layoutPcMi" class="nav-tab">
          <div @click="to('/classificationGoods')" class="all-present">
            <img
              src="http://cdn.weilf.cn/Templates/14/images/nav-list.png?v=biyao_27eef35"
              alt=""
            />
            全部礼品
          </div>
          <div
            @click="to('/classificationGoods', item)"
            v-for="(item, index) in navTabs"
            :key="index"
            class="nav-item"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div v-if="layoutPcMi" style="display: flex; justify-content: center">
        <router-view @collectChange="collectChange"></router-view>
      </div>
      <div v-else>
        <router-view @collectChange="collectChange"></router-view>
      </div>
    </el-main>
    <el-footer v-if="layoutPcMi">
      <div
        v-if="layoutPcMi"
        :class="layoutPcMi ? 'footer-top-content' : 'footer-top-content-ml'"
      >
        <!-- <div class="flex">
          <div class="item">
            <div class="title">信息咨询</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of infoConsulting"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">客户服务</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of customerService"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
          <div class="item" :style="!layoutPcMi ? 'margin-top:20px;' : ''">
            <div class="title">其他</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of other"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
          <div class="item" :style="!layoutPcMi ? 'margin-top:20px;' : ''">
            <div class="title">会员中心</div>
            <el-divider></el-divider>
            <div
              class="text"
              v-for="(item, i) of memberCenter"
              :key="i"
              @click="routerClick(item.path)"
            >
              <i class="el-icon-arrow-right"></i><span>{{ item.title }}</span>
            </div>
          </div>
        </div> -->
        <div class="bzbanner">
          <img
            src="http://cdn.weilf.cn/Templates/14/images/bz.png?v=1"
            alt=""
          />
        </div>
        <div class="footer-main">
          <div class="footer-item">
            <div class="footer-title">帮助中心</div>
            <div class="footer-item2">关于我们</div>
            <div class="footer-item2">联系我们</div>
            <div class="footer-item2">礼品咨讯</div>
          </div>
          <div class="footer-item">
            <div class="footer-title">畅销礼品</div>
            <div class="footer-item2">应季新品</div>
            <div class="footer-item2">商务礼品</div>
            <div class="footer-item2">数码产品</div>
          </div>
          <div class="footer-item">
            <div class="footer-title">热门分类</div>
            <div class="footer-item2">生活电器</div>
            <div class="footer-item2">家纺礼品</div>
            <div class="footer-item2">杯壶水具</div>
          </div>
          <div class="footer-attention">
            <div>关注小程序商城</div>
            <!-- <img
              src="https://smj2.51js.net.cn/jeupload/202206/14M8RvFrxEHYjq1mov1.jpg"
              alt=""
            /> -->
          </div>
          <div class="footer-relation">
            <div>7X24小时客服热线</div>
            <div>{{ lesseeMsg.market_phone || "-" }}</div>
            <!-- <a href="javascript:void(0)" id="contactQQ">在线客服</a> -->
            <!-- <a href="tencent://message/?uin=2690643175&Site=Sambow&Menu=yes"
              >在线咨询</a
            > -->

            <div
              style="cursor: pointer"
              @click="toQQ(lesseeMsg.market_qq || '')"
            >
              <!-- <img
                src="http://shop.shangjiu-gift.com.cn/Templates/20/images/zhongduan.png"
                alt=""
              /> -->
              <img
                src="http://smj3.51js.net.cn/img/upload2021/20230306/167811062437554.png"
                alt=""
              />

              在线联系
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom-content">
        <!-- <span>点为科技软件研发有限公司</span> -->
        <span
          >{{ lesseeMsg.copyright || "" }} |
          <a
            style="color: #777"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            >{{ lesseeMsg.record_number || "" }}</a
          ></span
        >
      </div>
    </el-footer>
    <!-- <el-backtop target=".aaaa"></el-backtop> -->
  </el-container>
</template>

<script>
import { http } from "../../../request/http";
export default {
  name: "index",
  data() {
    // BizQQWPA.addCustom({
    //   aty: "0", //指定工号类型,0-自动分流，1-指定工号，2-指定分组
    //   nameAccount: "2325043457", //指定的聊天 QQ 号码
    //   selector: "contactQQ", //触发聊天的标签id
    // });
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      drawer: false,
      infoConsulting: [
        {
          title: "关于我们",
          path: "/aboutUs",
        },
        {
          title: "配送信息",
          path: "/distributionInfo",
        },
        {
          title: "隐私策略",
          path: "/privacyPolicy",
        },
        {
          title: "使用条款",
          path: "/termsUse",
        },
      ],
      customerService: [
        {
          title: "联系我们",
          path: "/contactUs",
        },
        {
          title: "退换服务",
          path: "/goodsOrderReturn",
        },
        {
          title: "网站地图",
          path: "/",
        },
      ],
      other: [
        {
          title: "品牌专区",
          path: "/",
        },
        {
          title: "礼品卷",
          path: "/",
        },
        {
          title: "联盟会员",
          path: "/",
        },
        {
          title: "特别优惠",
          path: "/",
        },
      ],
      memberCenter: [
        {
          title: "会员中心",
          path: "/user",
        },
        {
          title: "历史订单",
          path: "/order",
        },
        {
          title: "收藏列表",
          path: "/myCollection",
        },
        {
          title: "订阅咨询",
          path: "/mailSubscribe",
        },
      ],
      members: [
        {
          title: "会员注册",
          activeName: "second",
        },
        {
          title: "会员登录",
          activeName: "first",
        },
      ],
      search: "",
      activeMenuIndex: "",
      token: localStorage.getItem("tokens"),
      layoutPcMi: this.$store.state.layoutPcMi,
      collectNum: 0,
      classificationList: [],
      navTabs: [
        "商城首页",
        // "秋冬新品",
        // "开门红",
        // "运动户外",
        // "促销赠品",
        // "代发专区",
        // "品牌专区",
        "关于我们",
      ],
      searchVal: "",
      lesseeMsg: {},
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.token = localStorage.getItem("tokens");
        console.log(this.token);
        this.routerToken();
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    toQQ(qq) {
      console.log(qq);
      if (!qq) return this.$message.error("未配置联系方式");
      window.location.href =
        // "tencent://message/?uin=2690643175&Site=Sambow&Menu=yes";
        `tencent://message/?uin=${qq}&Site=Sambow&Menu=yes`;
    },
    seek(type) {
      let route = this.$route.query;
      if (type == "reset") {
        this.searchVal = "";
        this.$router.replace(
          `/classificationGoods?reset=${
            route.reset == 1 ? 2 : route.reset == 2 ? 1 : 2
          }`
        );
        return;
      }

      if (this.$route.query.search == this.searchVal) {
        this.$router.replace(
          `/classificationGoods?search=${
            this.searchVal == "" ? "%" : this.searchVal
          }&repetition=${
            route.repetition == "" ? 1 : route.repetition == 2 ? 1 : 2
          }`
        );
        return;
      }
      this.$router.replace("/classificationGoods?search=" + this.searchVal);
    },
    // 跳转
    to(url, name) {
      if (name == "商城首页") {
        url = "/";
      } else if (name == "品牌专区") {
        url = "/brandlist";
      } else if (name == "关于我们") {
        url = "/aboutUs";
      }
      // location.reload()
      // this.$router.go(0)
      let route = this.$route.fullPath;
      if (route == url) {
        location.reload();
        return;
      }
      this.$router.push(url);
    },
    handleSelect(key, keyPath) {
      // location.reload()
      this.$router.push("/classificationGoods");
    },
    routerCollection() {
      this.$router.push("/myCollection");
    },
    routerCart() {
      this.$router.push("/shoppingCart");
    },
    routerClick(command) {
      this.token
        ? this.$router.push(`${command}`)
        : this.$router.push({
            name: "login",
            params: { activeName: command },
          });
    },
    routerToken() {
      if (this.token) {
        this.members = [
          {
            title: "会员中心",
            path: "/user",
          },
          {
            title: "我的订单",
            path: "/order",
          },
          {
            title: "我的余额",
            path: "/balance",
          },
          {
            title: "文件下载",
            path: "/fileDownload",
          },
          {
            title: "注销退出",
            path: "/exit",
          },
        ];
      }
    },
    //搜索
    searchBtn() {
      console.log(this.$route.path == "/search");
      if (!this.search) return;
      this.$router.push("/search?searchval=" + this.search);
      // this.$router.go(0)
    },
    // 获取收藏数量
    getCollect() {
      http("column.php", {
        fun: "getColumnCollect",
        module: "goods",
        type: "c",
      }).then((res) => {
        if (res.code == 0) {
          this.collectNum = res.data.length;
        }
      });
    },
    collectChange() {
      this.getCollect();
    },
    // 获取商品分类
    queryClassify() {
      let that = this;
      http("goods.php", {
        fun: "getGoodsType",
      }).then((res) => {
        if (res.code == 0) {
          that.classificationList = res.data;
        }
      });
    },
  },
  mounted() {
    // console.log(localStorage.getItem(''));
    this.routerToken();
    // this.getCollect();
    this.queryClassify();
    setTimeout(() => {
      this.lesseeMsg = JSON.parse(localStorage.getItem("lesseeMsg"));
    }, 1000);
  },
};
</script>
<style>
.el-menu {
  max-height: 500px !important;
  /* overflow-y: scroll !important; */
  overflow-x: hidden !important;
}
</style>
<style lang="scss" scoped>
@import "../../scss/index.scss";

:deep(.el-menu-item):hover {
  outline: 0 !important;
  color: red !important;
  background: #fff !important;
}
:deep(.el-submenu__title):hover {
  outline: 0 !important;
  color: red !important;
  background: #fff !important;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  color: #fff;
  padding: 10px 0;
  font-size: 12px;
  background: rgb(51, 51, 51);
  .header-left {
    display: flex;
    align-items: center;
    div {
      &:first-child {
        color: #ccc;
        cursor: pointer;
        margin-right: 20px;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    div {
      &:nth-child(1) {
        cursor: pointer;
      }
      &:nth-child(2) {
        color: #ddd;
        margin-left: 7px;
        margin-right: 15px;
        margin-top: 2px;
        cursor: pointer;
      }
    }
  }
  .header-right2 {
    display: flex;
    color: #ccc;
    div {
      &:nth-child(1) {
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
      &:nth-child(2) {
        margin: 0 20px;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
.nav {
  background: #fff;
  .nav-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    .nav-search {
      display: flex;
      align-items: center;
      input {
        width: 488px;
        height: 30px;
        border: 1px solid #ccc;
        outline: 0;
        border-right: 0;
        padding-left: 10px;
      }
      input::input-placeholder {
        font-size: 15px;
      }

      input::-webkit-input-placeholder {
        font-size: 15px;
      }

      input:-ms-input-placeholder {
        font-size: 15px;
      }

      input:-moz-placeholder {
        font-size: 15px;
      }

      input::-moz-placeholder {
        font-size: 15px;
      }
      .search-btn {
        border: 1px solid #ccc;
        height: 30px;
        line-height: 30px;
        padding: 1px 10px;
        cursor: pointer;
      }
    }
  }
  .nav-tab {
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    display: flex;
    align-items: center;
    .all-present {
      background: #f2f2f2;
      width: 180px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #c51323;
      }
    }
    .nav-item {
      border-right: 1px solid #ccc;
      padding: 0 17px;
      cursor: pointer;
      width: 80px;
      &:hover {
        color: #c51323;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}
.top-rigth {
  font-size: 0.75rem;
  display: flex;
  div {
    cursor: pointer;
    margin: 0 0.625rem;
  }
}
.star-off-class {
  @include circular;
  @include flexCenter;
  cursor: pointer;
}
.menu-div {
  background: #ccc;
  width: 0.5px;
  height: 17px;
  margin-top: 5px;
}

.footer-top-content {
  // background-color: #f1f2f4;
  // width: 100%;

  // display: flex;
  // justify-content: center;
  // margin-top: 1.25rem;
  margin-top: 100px;
  .bzbanner {
    display: flex;
    align-items: center;
    height: 100px;
    background: #fbfafb;
    img {
      width: 1200px;
      margin: 0 auto;
    }
  }
  .footer-main {
    width: 1080px;
    margin: 0 auto;
    display: flex;
    padding: 20px 0;
    .footer-item {
      margin-right: 120px;
      .footer-title {
        font-size: 16px;
        color: #333;
        font-weight: normal;
        margin-bottom: 20px;
      }
      .footer-item2 {
        font-size: 12px;
        color: #808080;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
          color: #c51323;
        }
      }
    }
    .footer-attention {
      font-size: 16px;
      border-right: 1px solid #ccc;
      padding-right: 130px;
      div {
        margin-bottom: 10px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
    .footer-relation {
      flex: 1;
      text-align: center;
      div {
        &:nth-child(2) {
          margin-top: 30px;
          font-size: 20px;
        }
        &:nth-child(3) {
          background: #e31436;
          color: #fff;
          width: 120px;
          height: 36px;
          line-height: 36px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 18px;
          font-size: 12px;
          margin-top: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .flex {
    width: 70%;
    @include flexCenter($justify: space-around, $align: unset);
    padding: 2.5rem 0;
    .item {
      width: 18%;
      .el-divider {
        margin: 0.625rem 0;
      }
      .title {
        font-size: 1.125rem;
      }
      .text {
        margin-top: 1.25rem;
        i {
          margin-right: 0.625rem;
        }
        span {
          color: #777777;
          font-size: 0.875rem;
          &:hover {
            display: inline-block;
            transform: translateX(1.25rem);
            cursor: pointer;
            transition: transform linear 500ms;
          }
        }
      }
    }
  }
}
.footer-top-content-ml {
  @extend .footer-top-content;
  .flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: unset;
    .item {
      width: 50%;
    }
  }
}
.footer-bottom-content {
  width: 100%;
  // background-color: #e4e5e7;
  padding: 1.25rem;
  text-align: center;
  box-sizing: border-box;
  color: #777777;
  font-size: 0.875rem;
}
</style>
<style lang="scss">
.el-header {
  padding: 0 !important;
  .el-divider {
    margin: 0;
  }
}
.el-main,
.el-footer {
  padding: 0 !important;
}
body > .el-container {
  margin-bottom: 40px;
}
</style>
