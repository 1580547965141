
/**
 * 获取日期和时间
 * @param {Date} date  日期 默认为当前日期
 * @return {String} yyyy-mm-dd hh:mm:ss 日期格式化
 */
function getYTDHMS (date = new Date()) {
  date = new Date(date);
  return date.getFullYear() + '-' +
    padStartTime(date.getMonth() + 1) + '-' + padStartTime(date.getDate()) + ' ' + padStartTime(date.getHours()) + ':' +
    padStartTime(date.getMinutes()) + ':' + padStartTime(date.getSeconds());
}


/**
 * 小于10前面加0
 * @param {Object} num 数
 * @return {String}
 */
function padStartTime (str) {
  return str < 10 ? '0' + str : str;
}

/**
 * 获取年月日 
 * @param {Date} date  日期 默认为当前日期
 * @return {String} yyyy-mm-dd 日期格式化
 */
function getYTD (date = new Date()) {
  date = new Date(date);
  return date.getFullYear() + '-'
    + padStartTime(date.getMonth() + 1) +
    '-' + padStartTime(date.getDate());
}

/**
 * 获得一个月有多少天,用户日历面板显示
 * @param {year} 年
 * @param {month} 月
 * @return {Array} 返回
 */
function getCountDays (year, month) {
  // 当月的第一天是周几
  let firstDay = new Date(year, month - 1, 1).getDay()
  // 当月的最后一天
  let lastDay = new Date(year, month, 0).getDate()
  // 获得填充的首行空天数
  const arrFull = new Array(firstDay).fill('');
  // 获得当月所有天数
  const days = new Array(lastDay).fill('').map((v, i) => i + 1)
  return [...arrFull, ...days];
}
export {
  getYTDHMS,
  padStartTime,
  getCountDays,
  getYTD
}