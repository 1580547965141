import md5 from "./MD5.js";
import { getYTDHMS } from "./date_utils.js";

function countDown() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  var week = now.getDay();
  return {
    hour,
    minute,
    second,
  };
}

/**
 * 算法获取秘钥
 */
function getSecret(fun, openid = "", appid = "") {
  let secret =
    "appid=" +
    appid +
    "fun=" +
    fun +
    "openid=" +
    openid +
    "timestamp=" +
    getYTDHMS() +
    "XYZ";
  return md5("ABC" + md5(secret));
}

/**
 * 加载中的消息提示框
 */
function toastLoading(msg = "加载中...") {
  uni.hideLoading();
  uni.showLoading({
    title: msg,
    icon: "",
    mask: true,
  });
}

var escapeHTML = (a) => {
  a = "" + a;
  return a
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

// 格式化金额
function changeMoney(num) {
  // if (Number(num) <= 1) return {
  // 	num,
  // 	unit: '元'
  // };
  // var moneyUnits = ["元", "万元", "亿元", "万亿"]
  var moneyUnits = ["", "万", "亿", "万亿"];
  var dividend = 10000;
  var curentNum = num;
  //转换数字
  var curentUnit = moneyUnits[0];
  //转换单位
  for (var i = 0; i < 4; i++) {
    curentUnit = moneyUnits[i];
    if (strNumSize(curentNum) < 5) {
      break;
    }
    curentNum = curentNum / dividend;
  }
  var m = {
    num: 0,
    unit: "",
  };
  // m.num = Number(curentNum).toFixed(2)
  m.num = Number(curentNum);
  m.unit = curentUnit;
  // return m;
  return `${m.num}${m.unit}`;
}
function strNumSize(tempNum) {
  var stringNum = tempNum.toString();
  var index = stringNum.indexOf(".");
  var newNum = stringNum;
  if (index != -1) {
    newNum = stringNum.substring(0, index);
  }
  return newNum.length;
}

// console.log(changeMoney(1)); // {num: '100.00', unit: '亿元'}

// 转义html
function htmlDecodeByRegExp(str) {
  var temp = "";
  if (str.length == 0) return "";
  temp = str.replace(/&amp;/g, "&");
  temp = temp.replace(/&lt;/g, "<");
  temp = temp.replace(/&gt;/g, ">");
  temp = temp.replace(/&nbsp;/g, " ");
  temp = temp.replace(/&#39;/g, "'");
  temp = temp.replace(/&quot;/g, '"');
  return temp;
}

export {
  countDown,
  getSecret,
  toastLoading,
  escapeHTML,
  changeMoney,
  htmlDecodeByRegExp,
};
